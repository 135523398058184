import logo from "./assets/image/logo.jpeg";
import "./App.css";
import Typewriter from "typewriter-effect";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <Typewriter
        wrapperClassName='App-link'
          options={{
            strings: ["Çok Yakında", "Coming Soon", "Väga varsti"],
            autoStart: true,
            loop: true,
          }}
        />
      </header>
    </div>
  );
}

export default App;
